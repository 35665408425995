import config from '$lib/config/client-config';
import { revision } from './revision';
import { truncateString } from '$lib/helpers/truncate-string';
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_ENV } from '$env/static/public';
import { building } from '$app/environment';
import { get } from 'svelte/store';
import { updated } from '$app/stores';
import { isExpired } from '$lib/helpers/is-expired';

function setErrorInDom(event) {
    if (!document) return;
    const error = document.createElement('div');
    const span = document.createElement('span');
    span.innerHTML = `Sorry an error ocurred (${event.event_id}). `;
    const reload = document.createElement('a');
    reload.setAttribute('class', 'cursor-pointer');
    reload.innerHTML = ` Reload the page,`;
    reload.onclick = () => {
        window.location.reload();
    };
    const contact = document.createElement('a');
    contact.innerHTML = ` or contact support`;
    contact.href = `mailto:support@21risk.com?subject=error id: ${event.event_id}`;

    error.appendChild(span);
    error.appendChild(reload);
    error.appendChild(contact);

    error.setAttribute(
        'class',
        'fixed bottom-0 leading-10 text-sm left-0 right-0 bg-red-500 dark:bg-red-600 text-white p-2'
    );
    document.body.appendChild(error);
}

/**************************************************************
 * If an unexpected error is thrown during loading or rendering, this function will be called with
 * the error, event, status code and message.
 ***************************************************************/
setupSentryOnClient();

const myErrorHandler = ({ error, event }) => {
    console.error('An error occurred on the client side:', error, event);
};

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);

// or alternatively, if you don't have a custom error handler:
// export const handleError = handleErrorWithSentry();
export function setupSentryOnClient(): void {
    if (PUBLIC_ENV == 'test' || building) {
        return;
    }
    // const isExpiredStore = getIsExpired();
    Sentry.init({
        dsn: config.sentryDsn,
        integrations: [Sentry.breadcrumbsIntegration({ console: false })],
        tracesSampleRate: 0.1,
        release: revision,
        autoSessionTracking: false,
        maxBreadcrumbs: 35,
        environment: PUBLIC_ENV,
        beforeSend(event, hint) {
            let shouldEventBeSent = true; // Default to send event

            const isUpdated = get(updated);
            Sentry.setTag('updated', isUpdated);

            const expired = isExpired();
            Sentry.setTag('expired', expired);
            if (expired) {
                console.log('beforeSend: expired is true');
                shouldEventBeSent = false;
            }

            // Do not send events in test or local environment
            if (PUBLIC_ENV == 'test' || PUBLIC_ENV === 'local') {
                shouldEventBeSent = false;
            }

            if (
                localStorage &&
                localStorage.getItem('eddySentry')?.includes('reportNoMatterWhat')
            ) {
                shouldEventBeSent = true;
            }
            const reportNoMatterWhat = `${hint?.originalException}`?.includes(
                '[reportNoMatterWhat]'
            );
            if (reportNoMatterWhat) {
                shouldEventBeSent = true;
            }
            if (
                ((hint?.originalException || ({} as any)).message || '').includes(
                    '[reportNoMatterWhat]'
                )
            ) {
                shouldEventBeSent = true;
            }
            // ONLY set error in DOM, if exception is NOT handled, and level is fatal or error
            let handled = false;

            if (
                event?.exception?.values &&
                event.exception.values.some((v) => v.value?.includes('dynamically imported module'))
            ) {
                handled = true;
                shouldEventBeSent = false; // Do not send failed to fetch dynamically imported module errors
            }

            if (event?.exception && event.exception.values && event.exception.values[0]) {
                if (
                    event.exception.values[0].mechanism &&
                    event.exception.values[0].mechanism.handled
                ) {
                    handled = true;
                }
                if (
                    event.exception.values[0].value ===
                    'ResizeObserver loop completed with undelivered notifications.'
                ) {
                    handled = true;
                    shouldEventBeSent = false; // Do not send ResizeObserver loop errors
                }
                if (
                    event.exception.values.some((v) =>
                        v.value?.toLowerCase().includes('failed to fetch')
                    )
                ) {
                    handled = true;
                    shouldEventBeSent = false; // Do not send failed to fetch errors
                }
            }

            const level = event.level;
            if (!handled && level && ['fatal', 'error'].includes(level)) {
                setErrorInDom(event);
            } else if (!handled && !level) {
                setErrorInDom(event);
            }

            if (shouldEventBeSent) {
                console.log(`sentry.ts; now sending event to sentry`, event);
                return event;
            }
            console.log(
                `Client: Sentry's beforeSend hook decided not report error. (are you on localhost or running tests?)`,
                event
            );
            return null;
        },
        beforeBreadcrumb(breadcrumb, hint) {
            /***************************************************************
             * Imporve UI click events
             * Based on: https://stackoverflow.com/q/60248712/3694288
             ***************************************************************/
            if (breadcrumb.category === 'ui.click') {
                try {
                    const target = hint?.event?.target as HTMLElement;
                    const nodeType = target?.nodeName?.toLowerCase();
                    const innerText = truncateString(target?.innerText, 15);
                    if (nodeType) {
                        breadcrumb.data = { nodeType, innerText };
                    }
                    const dataCyLabel = target?.getAttribute('data-cy');
                    const ariaLabel = (target as HTMLElement)?.getAttribute('aria-label');
                    const elementId = (target as HTMLElement)?.id;
                    if (dataCyLabel) {
                        breadcrumb.message = `${dataCyLabel} ${nodeType}`;
                    } else if (ariaLabel) {
                        breadcrumb.message = `${ariaLabel} ${nodeType}`;
                    } else if (elementId) {
                        breadcrumb.message = `${elementId} ${nodeType}`;
                    }
                } catch (error) {}
            }
            /***********************************************/
            const url = (breadcrumb?.data?.url as string) ?? '';
            if (!url) {
                // If the breadcrumb does not contain a url, allow!
                return breadcrumb;
            }
            if (breadcrumb.category === 'fetch' && url.includes('/graphql')) {
                return null;
            }
            if (!breadcrumb) return breadcrumb;
            /*
            We don't want the following events triggered as breadcrumbs.
            */
            const filterOutBreadcrumb = [
                'https://www.google-analytics.com',
                'http://api-eu.pusher.com',
                'https://api-eu.pusher.com',
                'https://betteruptime.com',
                '/_app/version.json',
                'https://sockjs-eu.pusher.com',
                '/_vercel/insights/',
                '/_vercel/speed-insights/',
            ];
            const filterOut = filterOutBreadcrumb.find((v) => url.includes(v));
            if (filterOut) {
                return null;
            }
            return breadcrumb;
        },
    });
    Sentry.setTag('svelteKit', 'browser');
    Sentry.setTag('locale', window?.navigator?.languages?.join('|'));
    Sentry.setTag('revision', revision);
}
