import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191'),
	() => import('./nodes/192'),
	() => import('./nodes/193'),
	() => import('./nodes/194'),
	() => import('./nodes/195'),
	() => import('./nodes/196'),
	() => import('./nodes/197'),
	() => import('./nodes/198'),
	() => import('./nodes/199'),
	() => import('./nodes/200'),
	() => import('./nodes/201'),
	() => import('./nodes/202'),
	() => import('./nodes/203'),
	() => import('./nodes/204'),
	() => import('./nodes/205'),
	() => import('./nodes/206'),
	() => import('./nodes/207'),
	() => import('./nodes/208'),
	() => import('./nodes/209'),
	() => import('./nodes/210'),
	() => import('./nodes/211'),
	() => import('./nodes/212'),
	() => import('./nodes/213'),
	() => import('./nodes/214'),
	() => import('./nodes/215'),
	() => import('./nodes/216'),
	() => import('./nodes/217'),
	() => import('./nodes/218'),
	() => import('./nodes/219'),
	() => import('./nodes/220'),
	() => import('./nodes/221'),
	() => import('./nodes/222'),
	() => import('./nodes/223'),
	() => import('./nodes/224'),
	() => import('./nodes/225'),
	() => import('./nodes/226'),
	() => import('./nodes/227'),
	() => import('./nodes/228'),
	() => import('./nodes/229'),
	() => import('./nodes/230'),
	() => import('./nodes/231'),
	() => import('./nodes/232'),
	() => import('./nodes/233'),
	() => import('./nodes/234'),
	() => import('./nodes/235')
];

export const server_loads = [0,27,23,7,24,8,9,4];

export const dictionary = {
		"/(marketing)": [170,[17]],
		"/(marketing)/about": [171,[17,18]],
		"/(admin)/admin": [35,[2]],
		"/(admin)/admin/abundant/add-update": [~36,[2]],
		"/(admin)/admin/abundant/duplicate-risk-type": [~37,[2]],
		"/(admin)/admin/abundant/evict-file-cache": [~38,[2]],
		"/(admin)/admin/abundant/groups": [~39,[2]],
		"/(admin)/admin/abundant/migrate-db": [~40,[2]],
		"/(admin)/admin/abundant/open-api": [41,[2]],
		"/(admin)/admin/abundant/queries-sg": [~42,[2]],
		"/(admin)/admin/abundant/reset-db": [~43,[2]],
		"/(admin)/admin/abundant/sync-indexes-to-db": [~44,[2]],
		"/(admin)/admin/abundant/sync-indexes-to-disk": [~45,[2]],
		"/(admin)/admin/abundant/sync-validation-to-db": [~46,[2]],
		"/(admin)/admin/abundant/sync-validation-to-disk": [~47,[2]],
		"/(admin)/admin/categories/add": [~48,[2]],
		"/(admin)/admin/charts/bar": [~49,[2]],
		"/(admin)/admin/dev-ops/analytics.calendar-month": [~50,[2]],
		"/(admin)/admin/dev-ops/analytics.items-per-month-full-load": [~51,[2]],
		"/(admin)/admin/dev-ops/analytics.items-per-month-incremental-load": [~52,[2]],
		"/(admin)/admin/dev-ops/find-unused-files": [~53,[2]],
		"/(admin)/admin/dev-ops/run-pipelines": [~54,[2]],
		"/(admin)/admin/misc/add-analytics-board": [~55,[2]],
		"/(admin)/admin/misc/create-thumbnails-by-id": [~56,[2]],
		"/(admin)/admin/misc/customer-scripts": [~57,[2]],
		"/(admin)/admin/misc/get-asset-info": [~58,[2]],
		"/(admin)/admin/misc/inspect-devalue": [59,[2]],
		"/(admin)/admin/misc/sendgrid-delete-contacts": [~60,[2]],
		"/(admin)/admin/risk-data/copy-risk-model-to-prod": [~61,[2]],
		"/(admin)/admin/risk-data/copy-risk-type": [~62,[2]],
		"/(admin)/admin/test-email": [~63,[2]],
		"/(admin)/admin/users/assign-permissions": [~64,[2]],
		"/(admin)/admin/users/delete": [~65,[2]],
		"/(admin)/admin/users/end-session": [~66,[2]],
		"/(admin)/admin/users/seed": [~67,[2]],
		"/(admin)/admin/users/set-user-tasks": [~68,[2]],
		"/(admin)/admin/users/toggle-21risk-admin": [~69,[2]],
		"/(marketing)/blog": [172,[17,19]],
		"/(marketing)/blog/[slug]": [173,[17,19]],
		"/(marketing)/changelog": [174,[17]],
		"/(marketing)/changelog/[slug]": [175,[17]],
		"/(marketing)/checklists": [~176,[17]],
		"/(marketing)/checklists/success": [180,[17]],
		"/(marketing)/checklists/view": [181,[17]],
		"/(marketing)/checklists/[_id=objectId]": [~177,[17]],
		"/(marketing)/checklists/[_id=objectId]/print": [~178,[17]],
		"/(marketing)/checklists/[_id=objectId]/request": [~179,[17]],
		"/(docs)/compliance": [164,[16]],
		"/(docs)/compliance/[...rest]": [~165,[16]],
		"/(marketing)/contact": [182,[17]],
		"/(marketing)/contact/sales": [~183,[17]],
		"/(marketing)/contact/sales/success": [184,[17]],
		"/(marketing)/customers": [185,[17,20]],
		"/(marketing)/customers/BHJ": [186,[17,20]],
		"/(marketing)/customers/[slug]": [187,[17,20]],
		"/(docs)/docs": [166,[16]],
		"/(docs)/docs/concepts": [168,[16]],
		"/(docs)/docs/concepts/[...rest]": [169,[16]],
		"/(docs)/docs/[...rest]": [~167,[16]],
		"/(reset)/emails/preview/[templateId]/[sendgridPayload]": [~219,[27]],
		"/(marketing)/experts": [188,[17,21]],
		"/(marketing)/experts/[slug]": [~189,[17,21,22]],
		"/(marketing)/experts/[slug]/success": [190,[17,21,22]],
		"/(marketing)/features/actions": [191,[17]],
		"/(marketing)/features/audits": [192,[17]],
		"/(unauthed)/feedback/emails/thank-you": [226,[29,30]],
		"/(unauthed)/feedback/emails/[emailId]": [~225,[29,30]],
		"/(marketing)/help": [~193,[17,23]],
		"/(marketing)/help/success": [194,[17,23]],
		"/(marketing)/legal": [195,[17]],
		"/(marketing)/legal/[slug]": [196,[17]],
		"/(unauthed)/library-public": [227,[29]],
		"/(unauthed-prerender)/login": [~231,[31,32]],
		"/(unauthed-prerender)/login/check": [~232,[31,32]],
		"/(authed)/logout": [~163,[7]],
		"/(admin)/mgmt": [70,[3]],
		"/(admin)/mgmt/analytics/overview": [~71,[3]],
		"/(unauthed)/misc/debug": [228,[29]],
		"/(unauthed)/misc/files-list": [~229,[29]],
		"/(marketing)/newsletter/check": [197,[17]],
		"/(marketing)/newsletter/subscribe": [~198,[17]],
		"/(marketing)/newsletter/success": [199,[17]],
		"/(marketing)/newsletter/verify": [~200,[17]],
		"/(unauthed)/no-access/[tenant_without_access=org_slug]": [~230,[29]],
		"/(reset)/onboarding": [220,[27,28]],
		"/(reset)/onboarding/1": [221,[27,28]],
		"/(reset)/onboarding/2": [~222,[27,28]],
		"/(reset)/onboarding/3": [~223,[27,28]],
		"/(reset)/onboarding/4": [224,[27,28]],
		"/(marketing)/partners": [~201,[17]],
		"/(marketing)/partners/success": [202,[17]],
		"/(marketing)/pricing": [203,[17]],
		"/(marketing)/roadmap": [204,[17]],
		"/(unauthed-prerender)/signup": [~233,[31,33]],
		"/(unauthed-prerender)/signup/check": [~234,[31,33]],
		"/(unauthed-prerender)/signup/complete": [~235,[31,33]],
		"/(marketing)/solutions/compliance": [205,[17]],
		"/(marketing)/solutions/insurance-values": [206,[17]],
		"/(marketing)/solutions/natcat": [207,[17]],
		"/(marketing)/solutions/natcat/[hazard]": [~208,[17]],
		"/(marketing)/solutions/property-insurance": [~209,[17]],
		"/(marketing)/solutions/property-insurance/roi-breakdown": [~210,[17]],
		"/(marketing)/solutions/property-insurance/roi-breakdown/success": [211,[17]],
		"/(marketing)/solutions/property-insurance/roi-calculator": [~212,[17]],
		"/(marketing)/updates": [213,[17,24]],
		"/(marketing)/updates/[slug]": [214,[17,24]],
		"/(marketing)/whitepapers": [215,[17,25]],
		"/(marketing)/whitepapers/success": [217,[17,25]],
		"/(marketing)/whitepapers/view/[uuid]": [218,[17,25]],
		"/(marketing)/whitepapers/[slug]": [~216,[17,25,26]],
		"/(authed)/[tenant=org_slug]/actions": [~94,[7,8,9]],
		"/(authed)/[tenant=org_slug]/actions/board": [96,[7,8,9]],
		"/(authed)/[tenant=org_slug]/actions/table": [~97,[7,8,9]],
		"/(authed)/[tenant=org_slug]/actions/[view]": [95,[7,8,9]],
		"/(authed)/[tenant=org_slug]/action/[itemId]": [~93,[7,8]],
		"/(app)/[tenant=org_slug]/analytics/[_id]": [72,[4]],
		"/(authed)/[tenant=org_slug]/automations/actions": [~98,[7,8,10]],
		"/(authed)/[tenant=org_slug]/automations/actions/new": [~100,[7,8,10]],
		"/(authed)/[tenant=org_slug]/automations/actions/[_id=objectId]": [~99,[7,8,10]],
		"/(authed)/[tenant=org_slug]/automations/models/[riskModelId=objectId]": [~101,[7,8,10]],
		"/(authed)/[tenant=org_slug]/automations/models/[riskModelId=objectId]/new": [~103,[7,8,10]],
		"/(authed)/[tenant=org_slug]/automations/models/[riskModelId=objectId]/[_id=objectId]": [~102,[7,8,10]],
		"/(authed)/[tenant=org_slug]/a/[reportId]/[[page]]": [~92,[7,8]],
		"/(app)/[tenant=org_slug]/board": [73,[4]],
		"/(app)/[tenant=org_slug]/board/[boardId]": [74,[4,5]],
		"/(app)/[tenant=org_slug]/board/[boardId]/edit": [~75,[4,5]],
		"/(authed)/[tenant=org_slug]/dashboard": [~104,[7,8]],
		"/(authed)/[tenant=org_slug]/dashboard/empty": [107,[7,8]],
		"/(authed)/[tenant=org_slug]/dashboard/[riskTypeId=objectId]": [~106,[7,8]],
		"/(authed)/[tenant=org_slug]/dashboard/[...rest]": [105,[7,8]],
		"/(authed)/[tenant=org_slug]/debug": [108,[7,8,11]],
		"/(authed)/[tenant=org_slug]/debug/config/print-values": [109,[7,8,11]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-10": [112,[7,8,11]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-11": [113,[7,8,11]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-12": [114,[7,8,11]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-1": [~111,[7,8,11]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-2": [~115,[7,8,11]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-30": [~117,[7,8,11]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-31": [~118,[7,8,11]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-32": [~119,[7,8,11]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-3": [116,[7,8,11]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-5": [~120,[7,8,11]],
		"/(authed)/[tenant=org_slug]/debug/errors/[...0]intro": [110,[7,8,11]],
		"/(authed)/[tenant=org_slug]/debug/forms/[...0]intro": [121,[7,8,11]],
		"/(authed)/[tenant=org_slug]/file-upload": [~122,[7,8]],
		"/(app)/[tenant=org_slug]/folder/[_id]": [76,[4]],
		"/(app)/[tenant=org_slug]/getting-started": [~77,[4]],
		"/(authed)/[tenant=org_slug]/insurance/get-started": [124,[7,8]],
		"/(authed)/[tenant=org_slug]/insurance/[[page]]": [123,[7,8]],
		"/(authed)/[tenant=org_slug]/landing-page": [125,[7,8]],
		"/(authed)/[tenant=org_slug]/library": [~126,[7,8]],
		"/(authed)/[tenant=org_slug]/library/edit/[_id=objectId]": [~128,[7,8]],
		"/(authed)/[tenant=org_slug]/library/new": [~129,[7,8]],
		"/(authed)/[tenant=org_slug]/library/[_id=objectId]": [~127,[7,8]],
		"/(authed)/[tenant=org_slug]/manage-data": [~130,[7,8,12]],
		"/(authed)/[tenant=org_slug]/manage-data/locations": [~131,[7,8,12,13]],
		"/(authed)/[tenant=org_slug]/manage-data/locations/import": [~133,[7,8,12,13]],
		"/(authed)/[tenant=org_slug]/manage-data/locations/[siteId]": [~132,[7,8,12,13]],
		"/(authed)/[tenant=org_slug]/manage-data/tags": [~134,[7,8,12]],
		"/(authed)/[tenant=org_slug]/manage-data/tags/[tagId]": [~135,[7,8,12]],
		"/(authed)/[tenant=org_slug]/members": [~136,[7,8]],
		"/(authed)/[tenant=org_slug]/members/new": [~138,[7,8]],
		"/(authed)/[tenant=org_slug]/members/[user_id]": [~137,[7,8]],
		"/(authed)/[tenant=org_slug]/models": [139,[7,8]],
		"/(app)/[tenant=org_slug]/new/board": [~78,[4]],
		"/(app)/[tenant=org_slug]/new/folder": [~79,[4]],
		"/(authed)/[tenant=org_slug]/profile": [~140,[7,8,14]],
		"/(authed)/[tenant=org_slug]/profile/account": [~141,[7,8,14]],
		"/(authed)/[tenant=org_slug]/profile/account/balance-changes": [~142,[7,8,14]],
		"/(authed)/[tenant=org_slug]/profile/account/roles/[roleId]": [~143,[7,8,14]],
		"/(authed)/[tenant=org_slug]/profile/billing": [144,[7,8,14]],
		"/(authed)/[tenant=org_slug]/profile/integrations": [145,[7,8,14]],
		"/(authed)/[tenant=org_slug]/profile/notifications": [146,[7,8,14]],
		"/(authed)/[tenant=org_slug]/reports/a": [~155,[7,8]],
		"/(authed)/[tenant=org_slug]/reports/request-audit": [~156,[7,8]],
		"/(authed)/[tenant=org_slug]/reports/[[view]]": [~154,[7,8,15]],
		"/(authed)/[tenant=org_slug]/report/new": [~150,[7,8]],
		"/(authed)/[tenant=org_slug]/report/new/[riskType=objectId]": [~151,[7,8]],
		"/(authed)/[tenant=org_slug]/report/new/[riskType=objectId]/[site=objectId]": [~152,[7,8]],
		"/(authed)/[tenant=org_slug]/report/start/[_id=objectId]": [~153,[7,8]],
		"/(authed)/[tenant=org_slug]/report/[_id=objectId]": [~147,[7,8]],
		"/(authed)/[tenant=org_slug]/report/[_id=objectId]/print": [~148,[7,8]],
		"/(authed)/[tenant=org_slug]/report/[_id=objectId]/qr-code": [~149,[7,8]],
		"/(authed)/[tenant=org_slug]/risk-reports": [157,[7,8]],
		"/(authed)/[tenant=org_slug]/risk-reports/[_id]": [158,[7,8]],
		"/(app)/[tenant=org_slug]/settings/account": [~80,[4,6]],
		"/(app)/[tenant=org_slug]/settings/account/roles/[roleId]": [~81,[4,6]],
		"/(app)/[tenant=org_slug]/settings/profile": [~82,[4,6]],
		"/(app)/[tenant=org_slug]/settings/sites": [~83,[4,6]],
		"/(app)/[tenant=org_slug]/settings/sites/import": [~85,[4,6]],
		"/(app)/[tenant=org_slug]/settings/sites/[siteId=objectId]": [~84,[4,6]],
		"/(app)/[tenant=org_slug]/settings/tags": [~86,[4,6]],
		"/(app)/[tenant=org_slug]/settings/tags/[tagId=objectId]": [~87,[4,6]],
		"/(app)/[tenant=org_slug]/settings/users": [~88,[4,6]],
		"/(app)/[tenant=org_slug]/settings/users/new": [~90,[4,6]],
		"/(app)/[tenant=org_slug]/settings/users/[user_id]": [~89,[4,6]],
		"/(authed)/[tenant=org_slug]/sign-in": [159,[7,8]],
		"/(authed)/[tenant=org_slug]/support": [160,[7,8]],
		"/(authed)/[tenant=org_slug]/test-error-load": [162,[7,8]],
		"/(authed)/[tenant=org_slug]/test-error": [161,[7,8]],
		"/(authed)/[...rest]": [91,[7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';